import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'

// @ts-ignore
import matomoHelperMethods from '@/helper/matomo' 

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      },
      {
        path: 'exhibitions/',
        name: 'Exhibition-List',
        component: () => import(/* webpackChunkName: "exhibitions-list" */ '@/views/Exhibition/index.vue'),
      },
      {
        path: 'exhibition/:id',
        name: 'Exhibition-Detail',
        component: () => import(/* webpackChunkName: "exhibition-detail" */ '@/views/Exhibition/exhibition-detail.vue'),
      },
      {
        path: 'exhibition/:typo3_id/audioguide',
        name: 'Audioguide-List',
        component: () => import(/* webpackChunkName: "audio-guide" */ '@/views/Audioguide/index.vue'),
      },
      {
        path: 'exhibition/:typo3_id/audioguide/:item_id',
        name: 'Audioguide-Item',
        component: () => import(/* webpackChunkName: "audio-guide" */ '@/views/Audioguide/audioguide-item.vue'),
      },
      {
        path: 'exhibition/:typo3_id/mediaguide',
        name: 'Mediaguide-List',
        component: () => import(/* webpackChunkName: "media-guide" */ '@/views/Audioguide/media-guide-index.vue'),
      },
      {
        path: 'exhibition/:typo3_id/mediaguide/:chapter_id',
        name: 'Mediaguide-Chapter',
        component: () => import(/* webpackChunkName: "media-guide" */ '@/views/Audioguide/media-guide-chapter.vue'),
      },
      {
        path: 'exhibition/:typo3_id/mediaguide/:chapter_id/:item_id',
        name: 'Mediaguide-Item',
        component: () => import(/* webpackChunkName: "media-guide" */ '@/views/Audioguide/media-guide-item.vue'),
      },
      {
        path: 'bundeskunsthalle-guide',
        name: 'Bundeskunsthalle-Guide',
        component: () => import(/* webpackChunkName: "bundeskunsthalle-guide" */ '@/views/Audioguide/buku-guide-index.vue'),
      },
      {
        path: 'bundeskunsthalle-guide/:chapter_id',
        name: 'Bundeskunsthalle-Guide-Chapter',
        component: () => import(/* webpackChunkName: "bundeskunsthalle-guide" */ '@/views/Audioguide/buku-guide-chapter.vue'),
      },
      {
        path: 'bundeskunsthalle-guide/:chapter_id/:item_id',
        name: 'Bundeskunsthalle-Guide-Item',
        component: () => import(/* webpackChunkName: "bundeskunsthalle-guide" */ '@/views/Audioguide/buku-guide-item.vue'),
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "search" */ '@/views/Search/index.vue'),
      },
      {
        path: 'services',
        name: 'Services',
        component: () => import(/* webpackChunkName: "services" */ '@/views/Services/index.vue'),
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "account" */ '@/views/Account/index.vue'),
      },
      {
        path: 'account/interest-settings',
        name: 'Account-Interest-Settings',
        component: () => import(/* webpackChunkName: "account-interest-settings" */ '@/views/Account/interest-settings.vue'),
      },
      {
        path: 'klimaticket',
        name: 'klimaticket',
        component: () => import(/* webpackChunkName: "klimaticket" */ '@/views/Klimaticket/embed.vue')
      },
      {
        path: 'auth/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/login.vue')
      },
      {
        path: 'auth/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "logout" */ '@/views/Auth/logout.vue')
      },
      {
        path: 'auth/signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "signup" */ '@/views/Auth/signup.vue')
      },
      {
        path: 'auth/recover-password',
        name: 'Recover-Password',
        component: () => import(/* webpackChunkName: "recover-password" */ '@/views/Auth/recover-password.vue')
      },
      {
        path: 'scheme',
        name: 'Custom Scheme',
        component: () => import(/* webpackChunkName: "custom-scheme" */ '@/views/CustomScheme/index.vue'),
      },
      {
        path: 'qr',
        name: 'QR',
        component: () => import(/* webpackChunkName: "qr" */ '@/views/CustomScheme/qr.vue'),
      },
      {
        path: 'legal-notice',
        name: 'Legal-Notice',
        component: () => import(/* webpackChunkName: "legal-notice" */ '@/views/Legal/legal-notice.vue')
      },
      {
        path: 'privacy-policy',
        name: 'Privacy-Policy',
        component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/Legal/privacy-policy.vue')
      },
      {
        path: 'umfrage/:id',
        name: 'survey',
        component: () => import(/* webpackChunkName: "survey" */ '@/views/Survey/index.vue')
      }
    ]
  },
  {
    path: '/welcome/audioguide',
    name: 'Audioguide-Welcome-Tour',
    component: () => import(/* webpackChunkName: "audio-guide-welcome-tour" */ '@/views/Audioguide/welcome-tour.vue'),
  },
  {
    path: '/qr',
    name: 'QR-Scan',
    component: () => import(/* webpackChunkName: "qr-scan" */ '@/views/Qr.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    console.log('router, loading chunk')
    if (!window.navigator.onLine) {
      alert('haste noch nicht besucht')
    }
  }
})

router.beforeEach(() => {
  matomoHelperMethods.setAppEnvironment()
  matomoHelperMethods.setAppVersion()
  matomoHelperMethods.setLoggedInStatus()
}) 

router.afterEach((to, from) => {
  console.log('[vue-router] afterEach.. from "' + from.path + '" to "' + to.path + '"')
  
  matomoHelperMethods.setAppEnvironment()
  matomoHelperMethods.setAppVersion()
  matomoHelperMethods.setLoggedInStatus()
  matomoHelperMethods.trackPageView(to.path)

  if (to.path !== from.path) {
    if (to.path.indexOf('auth') === -1) {
      localStorage.setItem("lastPageVisited", to.path);
    }

    const mediaGuideIntroShown = localStorage.getItem('mediaGuideIntroShown')
    if (to.path.indexOf('audioguide') && mediaGuideIntroShown === null) {
      localStorage.setItem("mediaGuideToLoadAfterTour", from.path);
      router.push({ name: 'Audioguide-Welcome-Tour' })
    }

    setTimeout(() => {
      window.scroll(0, 0)
    }, 200)
  }
})

export default router
